import React from "react";
import { render } from "react-dom";
import { Footer } from "./components/Footer";
import { QuestionAnswers } from "./components/QuestionAnswers";
import { Remark } from "./components/Remark";
import { Price } from "./components/Price";

const $footer = document.getElementById("footer")!;
const $questionAnswer = document.getElementById("question_answers")!;
const $remark = document.getElementById("restart_remark")!;
const $price = document.getElementById("price")!;
function renderApp() {
  render(<Price />, $price);
  render(<Footer />, $footer);
  if ($questionAnswer) {
    render(<QuestionAnswers />, $questionAnswer);
  }
  if ($remark) {
    render(<Remark />, $remark);
  }
}

renderApp();

if (module.hot) {
  module.hot.accept(renderApp);
}
