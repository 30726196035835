/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import { jsx, css } from "@emotion/core";
import { Fragment } from "react";

const Normal = () => (
  <Fragment>
    <p className="price_text">
      <img src={require("../image/price_text.svg")} alt="月額¥1,000" />
    </p>
  </Fragment>
);

const footnoteStyle = css`
  font-size: 12px;
  line-height: 1.5;
  width: 340px;
  text-align: left;
  margin-bottom: 1.5em;
`;
const campaignNoteImageStyle = css`
  width: 340px;
  height: 55px;
  margin-bottom: 1em;
`;
const NewYearCampaign2024 = () => {
  return (
    <Fragment>
      <p className="price_text">
        <img src={require("../image/price_text.svg")} alt="月額¥1,000" />
      </p>
      <div className="price_note">
        <img
          css={campaignNoteImageStyle}
          src={require("../image/campaign_note.png")}
          alt="白濱イズミからあなたに。新規メンバー新年キャンペーン♥"
          width="340"
          height="55"
        />
        <img
          src={require("../image/campaign_bnr.png")}
          alt="1000円off 今だけ。新規ご入会の方 30日分が¥0に。2024年1月4日(木)〜1月31日(水) の入会者限定"
          width="340"
          height="196"
        />
      </div>
      <p css={footnoteStyle}>
        *ご入会から30日分が無料トライアルとなります。
        <br />
        無料トライアル後、月額1,000円となります。
      </p>
    </Fragment>
  );
};

// ref: https://github.com/band-inc/izumi-community/pull/46
export const Price = () => {
  const newYearCampaign2024BeginningDate = new Date(
    "2024-01-04T00:00:00+09:00",
  );
  const newYearCampaign2024EndingDate = new Date("2024-02-01T00:00:00+09:00");
  const currentDate = new Date();
  const isNewYearCampaign2024 =
    newYearCampaign2024BeginningDate.getTime() <= currentDate.getTime() &&
    currentDate.getTime() < newYearCampaign2024EndingDate.getTime();
  return (
    <div className="price">
      <h2>
        <img src={require("../image/price.svg")} alt="Price" />
        <span>料金</span>
      </h2>
      {isNewYearCampaign2024 ? <NewYearCampaign2024 /> : <Normal />}
      <a href="#howto">入会方法はこちら</a>
    </div>
  );
};
