/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import { jsx, css } from "@emotion/core";

export const Remark = () => {
  const sectionStyle = () => {
    return css`
      margin: 0 auto;
      width: 85%;
      background: #5a4141;
      color: #fff;
      border-radius: 5px;
      max-width: 1000px;
    `;
  };
  const remarkStyle = () => {
    return css`
      padding: 2em;
      text-align: center;
      font-weight: bold;
    `;
  };
  return (
    <section css={sectionStyle}>
      <p css={remarkStyle}>只今、新規入会の準備中です。</p>
    </section>
  );
};
